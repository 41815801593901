@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
.distlist-side-panel,
.module__canvas main > aside.distlist-side-panel,
.distlist-single-side-panel,
.module__canvas main > aside.distlist-single-side-panel {
  .p-tabview-panel {
    overflow-y: auto;
  }

  .p-tabview-nav {
    flex: 0 0 auto;

    .p-tabview-ink-bar {
      display: none;
    }

    li:has(.p-tabview-nav-link) {
      cursor: pointer;
    }
  }

  .distlist-dl-panel__form,
  .distlist-dl-panel__messages {
    padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
    flex-grow: 1;
    overflow-y: auto;
    flex-direction: column;

    .form-input__container {
      align-items: normal;
    }

    label {
      font-family: OpenSans, sans-serif;
      font-weight: 700;
      font-size: Tools.asRem(13px);
      line-height: Tools.asRem(18px);
      text-align: left;
    }

    input.p-inputtext {
      background: Colors.$select-list-background;
      padding: Tools.asRem(Spacing.$gutter-small);
    }

    .p-dropdown {
      background-color: Colors.$dropdown-background;
      padding: Tools.asRem(Spacing.$gutter-small);

      .p-dropdown-trigger {
        height: Tools.asRem(18px);
        padding: 0;
      }

      .p-placeholder {
        color: Colors.$text-placeholder;
        font-style: italic;
        padding: 0;
      }

      &.p-invalid.p-component {
        border-color: Colors.$negative;
      }
    }

    .distlist-add-dl-button {
      width: max-content;
      padding: Tools.asRem(Spacing.$gutter-small);
      background: Colors.$full-black;
      border: 0;

      &.not-visible {
        visibility: hidden;
      }
    }

    .p-inputtextarea {
      font-family: OpenSans, sans-serif;
      font-size: Tools.asRem(13px);
      line-height: Tools.asRem(18px);
      font-weight: 400;
      background: Colors.$input-background-color;
      padding: Tools.asRem(Spacing.$gutter-small);
    }

    .message-invalid {
      font-size: Tools.asRem(13px);
      line-height: Tools.asRem(12px);
      font-weight: 400;
      padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
    }

    .distlist-dl-email-table-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      background-color: Colors.$select-list-background;

      .distlist-dl-email-table-new {
        background-color: transparent;
        border: 1px solid Colors.$dropdown-border;
        border-radius: 0;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        margin: 0;

        &:hover, &:focus {
          border-color: Colors.$input-border-hover;
        }

        &.p-invalid.p-component {
          border: 1px solid Colors.$negative;
        }
      }

      .dl-email-table-item-wrapper {
        border: 1px solid Colors.$dropdown-border;
        border-bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: column;

        &:first-child {
          border-top-left-radius: Tools.asRem(3px);
          border-top-right-radius: Tools.asRem(3px);
        }

        &:hover, &:focus, &:focus-within {
          border-color: Colors.$input-border-hover;

          & + .dl-email-table-item-wrapper,
          & + .distlist-dl-email-table-new {
            border-top-color: Colors.$input-border-hover;
          }
        }

        &:last-child {
          border-bottom: 1px solid Colors.$dropdown-border;
          border-bottom-left-radius: Tools.asRem(3px);
          border-bottom-right-radius: Tools.asRem(3px);

          &:hover, &:focus {
            border-color: Colors.$input-border-hover;
          }
        }
      }

      .dl-email-table-item {
        border: 0;
        line-height: Tools.asRem(18px);

        &.p-invalid {
          border: 1px solid Colors.$negative;
        }

        &__input {
          border: 0;
          border-radius: 0;
          padding: Tools.asRem(Spacing.$gutter-small);
          background-color: transparent;
        }

        button {
          border: 0;
          border-radius: 0;
          background-color: Colors.$full-black;
          padding: Tools.asRem(Spacing.$gutter-small);
          height: 100%;
        }
      }
    }
  }

  &-tabs {
    li.p-disabled {
      opacity: 0.36;
    }
  }

  .distlist-dl-panel__footer {
    justify-content: flex-end;
    background-color: Colors.$charcoal;
    border-top: 1px solid Colors.$full-black;
    flex-direction: row;
    padding: Tools.asRem(Spacing.$gutter-small);
  }

  .close-button {
    padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);

    @include Tools.top-right-element;
  }

  .dl-messages-container {
    display: flex;
    flex-direction: column;

    .dl-messages-search-container {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      padding: Tools.asRem(Spacing.$gutter-large) Tools.asRem(Spacing.$gutter-small);
      gap: Tools.asRem(Spacing.$gutter-small);

      input.p-inputtext,
      .dl-search-input {
        background: Colors.$input-background-color;
      }

      .dl-new-message {
        text-transform: uppercase;
        padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
        border: 0;
        border-radius: 4px;
        line-height: Tools.asRem(18px);
        height: Tools.asRem(34px);
        width: Tools.asRem(176px);
        justify-content: center;

        &.p-disabled {
          opacity: 0.36;
        }
      }
    }

    .dl-messages-empty {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: Spacing.$gutter-large;

      i:before {
        width: Tools.asRem(150px);
        height: Tools.asRem(150px);
        color: Colors.$text-secondary;
      }

      h2 {
        font-family: Panton, OpenSans, sans-serif;
        font-size: Tools.asRem(18px);
        line-height: Tools.asRem(22px);
        font-weight: 400;
        text-transform: uppercase;
        color: Colors.$text-secondary;
        margin: 0 Tools.asRem(Spacing.$gutter-large);
        text-align: center;
      }

      button {
        margin-top: Spacing.$gutter-large;
        justify-content: center;
      }
    }
  }

  .distlist__table--messages {
    height: auto;

    tr:not(.p-rowgroup-header):not(.p-rowgroup-footer) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr minmax(0, 1fr) Tools.asRem(105px);
      align-content: center;

      &.p-selectable-row:not(.p-highlight):not(:hover)[tabindex="0"] {
        outline: none;
      }
    }

    thead tr {
      border: 1px solid Colors.$datatable-header-border;
      border-left: 0;
      border-right: 0;
    }

    tbody {

      tr.p-datatable-emptymessage {
        min-height: 0;
        padding: 0;
      }

      td {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
        max-width: 100%;
        padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-large);
        height: Tools.asRem(38px);

        > div {
          display: block;

          > span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 0;
            max-width: 100%;
          }
        }

        &.column-status {
          font-style: italic;
          color: Colors.$text-secondary;
          display: flex;
        }

        &.column-action {
          padding: Tools.asRem(Spacing.$gutter-micro) 0 0;
        }


        i.pi-check {
          color: Colors.$positive;
        }

        i.pi-question-circle {
          margin-left:  Tools.asRem(Spacing.$gutter-small);
        }

        .not-available__container {
          font-style: italic;
          color: Colors.$text-secondary;
        }
      }

      tr.p-rowgroup-header {

        td {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          padding-left: Tools.asRem(42px);
          padding-right: Tools.asRem(20px);
          width: 100%;
          border-radius: 0;
          border: 0;

          .p-row-toggler {
            background-color: transparent;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            justify-content: flex-start;
            padding-left: Tools.asRem(10px);

            &:focus {
              outline: 0;
            }
          }

          .p-rowgroup-header-name {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            width: 100%;
            
            .dl-row-group-info {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              font-family: OpenSans, sans-serif;
              font-size: Tools.asRem(13px);
              line-height: Tools.asRem(18px);
              font-weight: 700;
              color: Colors.$text-primary;
              text-transform: none;
              min-width: 0;
            
              // title
              & :first-child {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              &-subtitle {
                font-family: OpenSans, sans-serif;
                font-style: italic;
                font-size: Tools.asRem(11px);
                line-height: Tools.asRem(15px);
                font-weight: 400;
                color: Colors.$text-secondary;
                text-transform: none;
              }
            }

            .dl-row-group-date {
              font-family: OpenSans, sans-serif;
              font-size: Tools.asRem(13px);
              line-height: Tools.asRem(18px);
              font-weight: 400;
              color: Colors.$text-secondary;
              width: Tools.asRem(170px);
              display: flex;
              justify-content: flex-end;
              align-items: center;
              padding: 0 Tools.asRem(Spacing.$gutter-small);
              text-transform: none;
            }
          }
        }
      }

      tr.p-datatable-row-expansion {
        display: none;
      }

      tr.p-rowgroup-footer {
        border: 0;

        &:empty {
          display: none;
        }

        .header-footer--no-items {
          justify-content: center;
        }

        .footer-sent-by {
          font-family: OpenSans, sans-serif;
          font-style: italic;
          font-size: Tools.asRem(11px);
          line-height: Tools.asRem(18px);
          font-weight: 400;
          color: Colors.$text-secondary;
          justify-content: flex-end;
          align-items: center;
          padding: 0 Tools.asRem(Spacing.$gutter-small);
        }
      }

      tr:has(+ .p-rowgroup-footer ),
      tr:has(+ .p-datatable-row-expansion + .p-rowgroup-footer ) {
        border-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: 960px) {
    border: 0;
    border-radius: 0;

    .distlist-dl-panel__form {
      margin-bottom: calc(Tools.asRem(46px) + Tools.asRem(Spacing.$gutter-small)); // footer height + padding
    }

    .title,
    .title:not(header):not(.p-tabview):not(section):not(div:first-child) {
      padding: Tools.asRem(Spacing.$gutter-micro) 0;
      margin: 0;
      font-weight: 350;
      font-size: Tools.asRem(18px);
      line-height: Tools.asRem(22px);
    }

    .back-button {
        background-color: transparent;
        padding: Tools.asRem(Spacing.$gutter-large) Tools.asRem(Spacing.$gutter-small);
        border: 0;
        margin-top: Tools.asRem(Spacing.$gutter-micro);
        height: Tools.asRem(32px);
        line-height: Tools.asRem(32px);
    }

    .p-inputtext,
    .p-inputtextarea {
      font-family: OpenSans, sans-serif;
      font-size: Tools.asRem(13px);
      line-height: Tools.asRem(18px);
      font-weight: 400;
      color: Colors.$text-primary;
    }

    .distlist-dl-panel__messages,
    .distlist-dl-panel__messages:not(header):not(.p-tabview):not(section):not(div:first-child) {
      padding: 0;
      flex-grow: 1;
      overflow-y: auto;
      flex-direction: column;
      margin-bottom: Tools.asRem(30px);

      > .title {
        padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
      }

      > .form-input__container {
        padding: 0 Tools.asRem(Spacing.$gutter-small);
      }
    }

    .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
      outline: 0;
    }

    .dl-messages__accordion {
      flex-direction: column;
      gap: Tools.asRem(Spacing.$gutter-small);
      padding: 0 !important; // override global (_accordion.scss)
      border: 0;
      width: 100%;

      > div {
        flex-direction: column;
      }

      &-tab {
        flex-direction: column;
        flex: 1 1 auto;
        border: 0;

        .p-accordion-content {
          overflow: visible;

          .dl-messages__loader {
            position: relative;
            width: 100%;
            height: Tools.asRem(35px);
          }

          .dl-messages__error-message {
            color: Colors.$negative;
          }
        }

        &.search-tab a {
          padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-large);
        }

        .dl-messages-search-container {
          flex-grow: 1;
          background: Colors.$dropdown-background;

          .p-inputtext {
            padding-left: Tools.asRem(40px);
          }
        }
      }

      &-header {
        border: 0;
        border-bottom: 2px solid Colors.$ob-brand-black;

        .p-accordion-header-link {
          padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-large);
          border: 0;
        }

        .p-accordion-header-text {
          padding: 0 Tools.asRem(Spacing.$gutter-small);
          flex-direction: column;
          flex: 1 1 auto;

          .dl-row-group-info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            font-family: OpenSans, sans-serif;
            font-size: Tools.asRem(13px);
            line-height: Tools.asRem(18px);
            font-weight: 700;
            color: Colors.$text-primary;
            text-transform: none;

            &-subtitle {
              font-family: OpenSans, sans-serif;
              font-style: italic;
              font-size: Tools.asRem(11px);
              line-height: Tools.asRem(15px);
              font-weight: 400;
              color: Colors.$text-secondary;
              text-transform: none;
            }
          }

          .dl-row-group-date {
            font-family: OpenSans, sans-serif;
            font-size: Tools.asRem(13px);
            line-height: Tools.asRem(32px);
            font-weight: 400;
            color: Colors.$text-secondary;
            width: Tools.asRem(170px);
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0;
            text-transform: none;
          }
        }
      }

      &-container {
        flex-grow: 1;
        padding: 0;

        .distlist__view {
          background-color: transparent;
        }

        .p-grid {
          flex-grow: 1;
        }
      }
    }
  }
}