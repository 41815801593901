@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.ma-question {
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: Tools.asRem(700px);
  max-width: Tools.asRem(700px);
  margin: Tools.asRem(Spacing.$gutter-small) 0;

  &-description {
    font-size: Tools.asRem(13px);
    color: Colors.$text-primary;
    margin-bottom: Tools.asRem(Spacing.$gutter-small);
    font-weight: 700;
    font-family: OpenSans, sans-serif;
    text-align: center;
  }

  &-bar {
    border: 1px solid Colors.$slate;
    border-radius: Tools.asRem(3px);

    &-dropdown-container {
      align-items: center;
      border-right: 1px solid Colors.$slate;
    }

    .p-dropdown:not(.p-disabled) {
      border: 0;
      height: Tools.asRem(32px);
      width: auto;
      min-width: Tools.asRem(153px);
      flex-grow: 0;
      outline: 0;
      padding: 0;
    }

    .p-dropdown-items-wrapper {
      overflow: hidden;
    }

    &-input {
      flex-direction: row;
      flex-grow: 1;
      align-items: flex-end;
      position: relative;
      width: 100%;

      input.p-inputtext,
      textarea.p-inputtextarea {
        border: 0;
        background: none;
        flex-grow: 1;
        color: Colors.$text-primary;
        align-content: center;
        min-height: Tools.asRem(52px);
        max-height: Tools.asRem(200px);
        padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(56px) Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-large);
        scrollbar-gutter: stable;
        overflow-y: auto !important;
        resize: none;
        field-sizing: content; // Commented in QuestionBar.tsx
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;

        &::-webkit-scrollbar {
          width: Tools.asRem(10px);
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: Colors.$charcoal;
        }
      }

      > button:last-child {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-large) Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-small);
        width: Tools.asRem(42px);
        height: Tools.asRem(36px);
        border-radius: Tools.asRem(4px);

        &.p-button.p-button-icon-only:not(.p-button-sm) span:first-child::before {
          width: Tools.asRem(18px);
          height: Tools.asRem(18px);
        }
      }
    }
  }

  &-context, &-warning {
    font-size: Tools.asRem(13px);
    line-height: Tools.asRem(18px);
    color: Colors.$text-secondary;
    margin-top: Tools.asRem(Spacing.$gutter-small);
    font-weight: 400;
    font-family: OpenSans, sans-serif;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: Tools.asRem(4px);
  }

  &-warning {
    margin-top: Tools.asRem(Spacing.$gutter-micro);

    &.warning-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: Tools.asRem(Spacing.$gutter-small);
    }
  }

  @media only screen and (max-width: 960px) {
    width: 100%;
    max-width: 100%;
    padding: 0 Tools.asRem(Spacing.$gutter-small);

    &-bar {
      border: 0;
      flex-direction: column;
      width: 100%;
      gap: Tools.asRem(Spacing.$gutter-small);

      &-dropdown-container {
        border-right: 0;
      }

      .p-dropdown:not(.p-disabled) {
        border: 1px solid Colors.$slate;
        border-radius: Tools.asRem(3px);
        width: 100%;
        height: Tools.asRem(58px);
        font-family: OpenSans, sans-serif;
        font-size: Tools.asRem(13px);
        font-weight: 400;
      }

      &-input {
        flex-direction: row;
        border: 1px solid Colors.$slate;
        border-radius: Tools.asRem(3px);
        align-items: flex-end;

        input.p-inputtext,
        textarea.p-inputtextarea,
        textarea.p-inputtextarea:focus {
          border: 0;
          flex-grow: 1;
          font-family: OpenSans, sans-serif;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5rem;

          &::placeholder {
            font-size: Tools.asRem(13px);
          }
        }
      }
    }
  }
}